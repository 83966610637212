<template>
  <div
    class="csn-lsm"
    :class="{ 'csn-lsm-displayed': SHOWS_REGISTER_SIDE_MENU }"
  >
    <div class="csn-lsm-header">
      <button @click="handleArrowClick" class="csn-lsm-arrow-back">
        <SlideArrow />
      </button>
      <NavbarLogoButton @click.native="handleCloseClick" />
      <button @click="handleCloseClick" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>
    <span class="csn-lsm-register-title">{{ t('register_account') }}</span>
    <RegistrationForm
      formId="registration-side-menu-form"
      @resendConfirmMail="closeMenu"
      @login="closeMenu"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  REGISTER_SIDE_MENU,
  SHOWS_REGISTER_SIDE_MENU,
  TOGGLE_MAIN_MENU,
  TOGGLE_REGISTER_SIDE_MENU,
  Module,
  RouteName,
} from '@/constants'
import { navigateTo } from '@/helpers'

export default {
  name: REGISTER_SIDE_MENU,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    NavbarLogoButton: () =>
      import('@/App/components/Navbar/components/NavbarLogoButton'),
    RegistrationForm: () =>
      import('@/views/Registration/components/RegistrationForm'),
  },
  computed: {
    ...mapState(Module.MAIN, [SHOWS_REGISTER_SIDE_MENU]),
    t() {
      return this.$createComponentTranslator(REGISTER_SIDE_MENU)
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_MAIN_MENU, TOGGLE_REGISTER_SIDE_MENU]),
    closeMenu() {
      this.TOGGLE_REGISTER_SIDE_MENU(false)
    },
    async handleArrowClick() {
      await navigateTo({
        name: RouteName.HOME,
      })

      this.closeMenu()
      this.TOGGLE_MAIN_MENU()
    },
    handleCloseClick() {
      this.closeMenu()

      navigateTo({
        name: RouteName.HOME,
      })
    },
  },
}
</script>
